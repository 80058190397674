import React from "react";
import {FlexContent, FlexContentAlignment, FlexContentDirection} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../utils/constants";
import {SvgLogoStripe} from "../svg";

const WatermarkStripe: React.FC = () => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);

    return (
        <FlexContent
            direction={FlexContentDirection.ROW}
            alignment={FlexContentAlignment.CENTER}
            cssClass="watermark-stripe"
        >
            <span>{t("powered_by")}</span>
            <SvgLogoStripe/>
        </FlexContent>
    );
};

export default WatermarkStripe;
