import React, {useEffect, useState} from "react";
import {
    Alert,
    AlertSeverity,
    Box,
    BoxSpacing,
    Button,
    ButtonLink,
    ButtonLinkStyle,
    ButtonSave,
    ButtonSize,
    ButtonStyle,
    ContentBlock,
    FieldBlock,
    FlexContent,
    FlexContentAlignment,
    FlexContentDirection,
    FlexContentSpacing,
    FormLayoutButtons,
    FormLayoutColumns,
    FormLayoutMention,
    FormLayoutRows,
    InputText,
    SelectAutocomplete
} from "@sirdata/ui-lib";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

import getApiErrorTranslationKey from "../../api/model/ApiErrors";
import useRegister from "../../utils/hooks/useRegister";
import {BillingInfo} from "../../api/model/billing/BillingInfo";
import {BillingInfoField} from "../../api/model/billing/BillingInfoField";
import {Country, CountryCode} from "../../common/api/model/Country";
import {FormLayoutMessage} from "../../common/component/snippet";
import {handleCatchError} from "../../common/utils/helper";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {LogoSirdataOnboarding} from "../../component/snippet";
import {pathRegister, pathRegisterBilling, TranslationPortalFile} from "../../utils/constants";
import {session} from "../../api/ApiSession";

function RegisterBilling() {
    const navigate = useNavigate();
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textBillingInfo} = useTranslation(TranslationPortalFile.BILLING_INFO);
    const {t: textOnboarding} = useTranslation(TranslationPortalFile.ONBOARDING);
    const [registerer, {goToNext}] = useRegister(pathRegisterBilling);
    const [countries, setCountries] = useState<Country[]>([]);
    const [billingInfo, setBillingInfo] = useState<BillingInfo>(new BillingInfo());
    const [apiErrorMessage, setApiErrorMessage] = useState("");
    const [isSubmitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (!registerer.isOriginStepAllowed) {
            navigate(pathRegister, {replace: true});
        } else {
            (async function () {
                try {
                    const countries = await session.getCountries();
                    setCountries(countries);

                    const account = await session.getPartnerAccount();
                    setBillingInfo(new BillingInfo({company_name: account.partner.company}));
                } catch (e) {
                }
            })();
        }
    }, [navigate, registerer.isOriginStepAllowed]);

    useEffect(() => {
        (async function () {
            try {
                const partnerBillingInfo = await session.restBilling.getPartnerBillingInfo();
                setBillingInfo(partnerBillingInfo);
            } catch (e) {
            }
        })();
    }, []);

    const handleSubmit = async () => {
        try {
            setApiErrorMessage("");
            setSubmitting(true);
            await session.restBilling.updatePartnerBillingInfo(billingInfo);
            goToNext();
        } catch (e) {
            handleCatchError(e, (e) => {
                if (e.statusCode === HttpStatusCode.UNAUTHORIZED) {
                    navigate(pathRegister, {replace: true});
                } else {
                    setApiErrorMessage(t(`error.${getApiErrorTranslationKey(e.message)}`));
                }
            });
            setSubmitting(false);
        }
    };

    const hasEmptyField = () => {
        return !billingInfo.company_name || !billingInfo.contact_firstname || !billingInfo.contact_lastname || !billingInfo.address || !billingInfo.postal_code || !billingInfo.city;
    };

    const currentCountry = countries.find((c) => c.isocode === billingInfo.country_iso);

    return (
        <div className="wrapper onboarding">
            <div className="register-page">
                <div className="register-form">
                    <div className="main-logo">
                        <LogoSirdataOnboarding/>
                    </div>
                    <div className="h1 title" dangerouslySetInnerHTML={{__html: textOnboarding("register_billing.title", {step: session.onboarding.getCurrentStep(pathRegisterBilling), steps: session.onboarding.getTotalSteps()})}}/>
                    <div className="description">{textOnboarding("register_billing.description")}</div>
                    <ContentBlock cssClass="register-form__block">
                        <Box spacing={BoxSpacing.XLARGE}>
                            <FormLayoutRows>
                                <FieldBlock label={textBillingInfo(`field.${BillingInfoField.COMPANY_NAME}`)} required>
                                    <InputText
                                        placeholder={textBillingInfo(`placeholder.${BillingInfoField.COMPANY_NAME}`)}
                                        value={billingInfo.company_name}
                                        onChange={(value) => setBillingInfo((prevState) => new BillingInfo({...prevState, [BillingInfoField.COMPANY_NAME]: value}))}
                                        autoFocus
                                    />
                                </FieldBlock>
                                <FormLayoutColumns>
                                    <FieldBlock label={textBillingInfo(`field.${BillingInfoField.CONTACT_FIRSTNAME}`)} required>
                                        <InputText
                                            placeholder={textBillingInfo(`placeholder.${BillingInfoField.CONTACT_FIRSTNAME}`)}
                                            value={billingInfo.contact_firstname}
                                            onChange={(value) => setBillingInfo((prevState) => new BillingInfo({...prevState, [BillingInfoField.CONTACT_FIRSTNAME]: value}))}
                                        />
                                    </FieldBlock>
                                    <FieldBlock label={textBillingInfo(`field.${BillingInfoField.CONTACT_LASTNAME}`)} required>
                                        <InputText
                                            placeholder={textBillingInfo(`placeholder.${BillingInfoField.CONTACT_LASTNAME}`)}
                                            value={billingInfo.contact_lastname}
                                            onChange={(value) => setBillingInfo((prevState) => new BillingInfo({...prevState, [BillingInfoField.CONTACT_LASTNAME]: value}))}
                                        />
                                    </FieldBlock>
                                </FormLayoutColumns>
                                <FormLayoutColumns>
                                    <FieldBlock label={textBillingInfo(`field.${BillingInfoField.ADDRESS}`)} required>
                                        <InputText
                                            placeholder={textBillingInfo(`placeholder.${BillingInfoField.ADDRESS}`)}
                                            value={billingInfo.address}
                                            onChange={(value) => setBillingInfo((prevState) => new BillingInfo({...prevState, [BillingInfoField.ADDRESS]: value}))}
                                        />
                                    </FieldBlock>
                                    <FieldBlock label={textBillingInfo(`field.${BillingInfoField.POSTAL_CODE}`)} required>
                                        <InputText
                                            placeholder={textBillingInfo(`placeholder.${BillingInfoField.POSTAL_CODE}`)}
                                            value={billingInfo.postal_code}
                                            onChange={(value) => setBillingInfo((prevState) => new BillingInfo({...prevState, [BillingInfoField.POSTAL_CODE]: value}))}
                                        />
                                    </FieldBlock>
                                </FormLayoutColumns>
                                <FormLayoutColumns>
                                    <FieldBlock label={textBillingInfo(`field.${BillingInfoField.CITY}`)} required>
                                        <InputText
                                            placeholder={textBillingInfo(`placeholder.${BillingInfoField.CITY}`)}
                                            value={billingInfo.city}
                                            onChange={(value) => setBillingInfo((prevState) => new BillingInfo({...prevState, [BillingInfoField.CITY]: value}))}
                                        />
                                    </FieldBlock>
                                    <FieldBlock label={textBillingInfo(`field.${BillingInfoField.COUNTRY}`)} required>
                                        <SelectAutocomplete
                                            options={countries.map((country) => ({value: country.isocode, label: country.name}))}
                                            value={currentCountry?.isocode || CountryCode.FRANCE.code}
                                            onChange={(option) => setBillingInfo((prevState) => new BillingInfo({...prevState, [BillingInfoField.COUNTRY]: option?.value}))}
                                        />
                                    </FieldBlock>
                                </FormLayoutColumns>
                                <FormLayoutColumns>
                                    <FieldBlock label={textBillingInfo(`field.${BillingInfoField.BUSINESS_ID}`)}>
                                        <InputText
                                            placeholder={textBillingInfo(`placeholder.${BillingInfoField.BUSINESS_ID}`)}
                                            value={billingInfo.business_id}
                                            onChange={(value) => setBillingInfo((prevState) => new BillingInfo({...prevState, [BillingInfoField.BUSINESS_ID]: value}))}
                                        />
                                    </FieldBlock>
                                    <FieldBlock label={textBillingInfo(`field.${BillingInfoField.VAT}`)}>
                                        <InputText
                                            placeholder={textBillingInfo(`placeholder.${BillingInfoField.VAT}`)}
                                            value={billingInfo.vat}
                                            onChange={(value) => setBillingInfo((prevState) => new BillingInfo({...prevState, [BillingInfoField.VAT]: value}))}
                                        />
                                    </FieldBlock>
                                </FormLayoutColumns>
                                <FormLayoutMention/>
                                <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.XSMALL} alignment={FlexContentAlignment.CENTER}>
                                    <FormLayoutButtons>
                                        <Button size={ButtonSize.BIG} style={ButtonStyle.DEFAULT_MIDNIGHT} onClick={() => navigate(session.onboarding.getPrevStepPath(pathRegisterBilling))}>{textOnboarding("form.button.previous")}</Button>
                                        <ButtonSave disabled={hasEmptyField()} onClick={handleSubmit} loading={isSubmitting}/>
                                    </FormLayoutButtons>
                                    {apiErrorMessage &&
                                        <FormLayoutMessage message={apiErrorMessage} severity={AlertSeverity.DANGER}/>
                                    }
                                </FlexContent>
                                {session.onboarding.isStepSkippable(pathRegisterBilling) &&
                                    <FormLayoutButtons>
                                        <ButtonLink style={ButtonLinkStyle.MIDNIGHT_LIGHT} onClick={goToNext}>
                                            {textOnboarding("form.button.ignore_step")}
                                        </ButtonLink>
                                        <Alert
                                            severity={AlertSeverity.WARNING}
                                            text={textOnboarding(`register_billing.warning.${session.onboarding.origin.name}`, textOnboarding("register_billing.warning.default"))}
                                            fullWidth
                                        />
                                    </FormLayoutButtons>
                                }
                            </FormLayoutRows>
                        </Box>
                    </ContentBlock>
                </div>
            </div>
        </div>
    );
}

export default RegisterBilling;
